import React, { useState } from "react";
import PropTypes from "prop-types";

import { v4 } from "uuid";
import LocalizedLink from "./localizedLink";

function MdxNav({ data }) {
  const navClass =
    "py-2 px-3 no-underline block text-white mx-2 hover:text-jungle-100";

  const activeClass = "text-jungle-100 bg-jungle-400 rounded";

  return (
    <header className="pt-6 pb-3 text-center bg-jungle-300 bg-edge bg-edge--be-300">
      <ul className="mx-auto inline-flex flex-wrap justify-center border-jungle-400 px-8 text-sm xl:text-base whitespace-no-wrap">
        {data.edges.map(item => {
          return (
            <li key={v4()}>
              <LocalizedLink
                to={`/${item.node.parent.relativeDirectory}`}
                className={navClass}
                activeClassName={activeClass}
              >
                {item.node.frontmatter.title}
              </LocalizedLink>
            </li>
          );
        })}
      </ul>
    </header>
  );
}

// MdxNav.propTypes = {
//   data: PropTypes.objectOf.isRequired
// };

export default MdxNav;
