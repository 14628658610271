import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Wrap from "../components/wrap";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "../components/mdxLink";
import MdxNav from "../components/mdxNav";

function projectPage({ data: { mdx, nav, imagePath } }) {
  return (
    <>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.intro} />
      <MdxNav data={nav} />

      <section className="bg-jungle-400 flex flex-col md:flex-row">
        <div className="order-2 md:order-1 md:flex-3 p-4 pt-10 md:p-16 text-left text-white">
          <h1 className="c-h1">{mdx.frontmatter.title}</h1>
          <p className="c-p-xl">{mdx.frontmatter.intro}</p>
        </div>
        <div
          style={{
            backgroundImage: `url(${imagePath?.childImageSharp?.fluid?.src})`,
          }}
          className="order-1 md:order-2 md:flex-2 bg-cover w-full h-64 md:h-auto bg-right"
        ></div>
      </section>
      <div className="xl:bg-leaf-3">
        <Wrap maxWidth="max-w-2xl c-markdown">
          <MDXProvider
            components={{
              a: MdxLink,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </Wrap>
      </div>
    </>
  );
}

export default projectPage;

export const query = graphql`
  query Project($locale: String!, $title: String!, $imagePath: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        intro
      }
      body
    }
    nav: allMdx(
      filter: {
        frontmatter: { templateKey: { eq: "project" } }
        fields: { locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
    imagePath: file(relativePath: { eq: $imagePath }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 400) {
          src
          srcSet
        }
      }
    }
  }
`;
